import React, { useRef, useState, useEffect } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import LocalOfferRoundedIcon from "@mui/icons-material/LocalOfferRounded";
import {
  CircularProgress,
  IconButton,
  Checkbox,
  Box,
  FormControl,
  Paper,
  Radio,
  Typography,
} from "@mui/material";
import { POST } from "../../../../config/Api";
import { Constants, userRoles } from "../../../../config/Constants";
import { useQueryParams } from "../../../../utils";
import ButtonMain from "../../../ButtonMain";
import BlockActions from "../BlockActions/BlockActions";
import "./MCQView.scss";
import ArtView from "../ArtView/ArtView";
import { ToastNotify } from "../../../SnackBar/ToastNotify";
import { cloudfrontUrl } from "../../../../config/BaseUrl";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LearnerProperties from "../../LearnerProperties";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "right",
  justifyContent: "right",
  color: theme.palette.common.white,
}));

function checkIds(ids, objects) {
  // Iterate through each id in the ids array
  return ids?.every((id) => {
    // Find the object with the matching _id
    const obj = objects?.find((item) => item?._id?.toString() === id);

    // Return false if the object doesn't exist or is_correct is false
    return obj && obj?.is_correct === false;
  });
}

const MCQView = ({
  mcq,
  courseDetails,
  blockUserActivities,
  courseDetail,
  setmodalOpen,
  setblockModOpen,
  block,
  disableBlock,
  isHighlight,
  setisHighlight,
}) => {
  let UserData = useSelector((state) => state.userLogin);

  const { cid } = useQueryParams();
  let location = useLocation();

  const [selectedOption, setSelectedOption] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [hidden, setHidden] = useState(block?.is_hidden);
  const [checkUserInputs, setcheckUserInputs] = useState(false);
  const [blockActivity, setBlockActivity] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hoveredOption, setHoveredOption] = useState(null);
  const [highlightOnReviewerMode, setHighlightOnReviewerMode] = useState(false);

  const open = Boolean(anchorEl);

  const handleOptionHover = (id) => {
    setHoveredOption(id);
  };

  const handleOptionLeave = () => {
    setHoveredOption(null);
  };

  const handleBlockProperties = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const toastRef = useRef();
  // const { authStates, setAuthStates } = useContext(AuthContext);

  let { isLoggedIn, user } = useSelector((state) => state.userLogin?.data) || {};

  let isModerator =
    courseDetails?.creator === user?.id ||
    courseDetails?.members?.findIndex((item) => item?.user === user?.id);
  const { userDetails } = useOutletContext();

  useEffect(() => {
    if (
      location.pathname.startsWith("/course/createblock") ||
      location.state?.userData?.role === userRoles.REVIEWER
    ) {
      setDisabled(true);
    }
  }, [location]);

  useEffect(() => {
    const data = blockUserActivities?.data?.find((item) => item.block_id === mcq._id);
    setSelectedOption(data?.mcq_option_ids || []);

    setBlockActivity(data);

    if (
      location.state?.userData?.role === userRoles.REVIEWER &&
      checkIds(data?.mcq_option_ids, mcq?.mcqs)
    ) {
      setHighlightOnReviewerMode(true);
    }

    if (data) {
      setcheckUserInputs(true);
    } else {
      setcheckUserInputs(false);
    }
  }, [blockUserActivities, mcq]);

  const handleHover = () => {
    setIsHovering(true);
  };
  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const handleOptionClick = (id, is_multiple) => {
    if (isLoggedIn) {
      setisHighlight &&
        setisHighlight((prevState) => prevState?.filter((item) => item !== mcq?._id));
      if (is_multiple) {
        toggleSelect(id, setSelectedOption);
      } else {
        setSelectedOption([id]);
      }
    } else {
      window.location.replace(
        `${process.env.REACT_APP_AUTH_URL_FE}?referrer=${window.location.href}`,
      );
      // setAuthStates((prev) => ({
      //   ...prev,
      //   loginOpen: true,
      //   message: LoginMessage,
      //   forceUpdate: !authStates.forceUpdate,
      //   modalOpen: true,
      //   signupOpen: false,
      //   forgotOpen: false,
      // }));
    }
  };

  const toggleSelect = (id, setSelectedOption) => {
    setSelectedOption((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((item) => item !== id)
        : [...prevSelected, id],
    );
  };

  const handleMcqSubmit = async () => {
    if (isLoggedIn) {
      if (!selectedOption.length) {
        toastRef.current.showToastMessage("Please select any mcq option.", "error");
      } else {
        setDisabled(true);
        setLoading(true);
        try {
          let body = {
            user_id: UserData?.data?.user?.id,
            course_id: cid,
            activity: "mcq",
            mcq_option_ids: selectedOption,
          };
          //submit MCQ answers
          await POST(`${Constants.saveAndUpdateUserActivities}/${mcq?._id}`, body);
          toastRef.current.showToastMessage(
            "Your response has been submitted successfully.",
            "success",
          );
          setcheckUserInputs(true);
          setDisabled(false);
          setLoading(false);
          //save user last activity
          await POST(
            `${Constants.userActivity}?activity=${
              checkUserInputs ? "blockActivityUpdate" : "blockActivitySave"
            }`,
            {
              user: UserData?.data?.user,
            },
          );
        } catch (error) {
          toastRef.current.showToastMessage("Something went wrong.", "error");
        }
      }
    }
  };

  return (
    <>
      {(isModerator === -1 || isModerator === undefined) && block?.is_hidden ? (
        ""
      ) : (
        <>
          <div
            className={` container-block mcq-block ${
              (isHighlight || highlightOnReviewerMode) && "highlightBlock"
            } ${disableBlock ? "disableBlock" : ""}
        ${mcq?.theme ? mcq?.theme : "postBgColor"}
      `}
            style={{
              background: `${mcq?.theme_type === "color" ? mcq?.color : ""}`,
            }}
            onMouseOver={(e) => {
              e.stopPropagation();
              handleHover();
            }}
            onMouseLeave={(e) => {
              e.stopPropagation();
              handleMouseLeave();
            }}
          >
            {isModerator !== -1 && isLoggedIn && isHovering && (
              <BlockActions
                blockId={mcq?._id}
                block={mcq}
                setmodalOpen={setmodalOpen}
                setblockModOpen={setblockModOpen}
                hidden={hidden}
                setHidden={setHidden}
              />
            )}

            <div className="title_container">
              {mcq.is_required && <div className="required">*</div>}
              <div className="block-title" dangerouslySetInnerHTML={{ __html: mcq.title }}></div>
            </div>
            {(mcq.mcqs.length !== 0 || mcq.mcqs.length !== null) && (
              <div className="block-options" style={{ marginBottom: "4px" }}>
                {mcq.mcqs?.map((item, index) => (
                  <>
                    {mcq?.mcq_style === "Popular Style" ? (
                      <FormControl
                        key={index}
                        style={{
                          display: "flex",
                          cursor: "pointer",
                          justifyContent: "center",
                          backgroundColor: hoveredOption === item._id ? "#f0f0f0" : "inherit",
                          border: selectedOption.includes(item?._id) ? "2px solid blue" : "none",
                        }}
                        onMouseOver={() => handleOptionHover(item._id)}
                        onMouseLeave={handleOptionLeave}
                        onClick={() =>
                          !disableBlock && handleOptionClick(item._id, mcq?.is_multiple)
                        }
                      >
                        <Box sx={{ alignItems: "center", mt: 2, ml: 3, display: "flex" }}>
                          {mcq?.is_multiple ? (
                            <Checkbox
                              name="isCorrect"
                              disabled={!item?._id}
                              checked={selectedOption.includes(item?._id)}
                            />
                          ) : (
                            <Radio
                              name="isCorrect"
                              disabled={!item?._id}
                              checked={selectedOption.includes(item?._id)}
                            />
                          )}
                          <Box>
                            <Typography
                              variant="outlined"
                              label="Option Text"
                              disabled={item?._id}
                              sx={{ flex: 1 }}
                            >
                              {item?.text}
                            </Typography>
                          </Box>
                        </Box>
                        {item?.image && (
                          <Paper
                            elevation={2}
                            sx={{
                              display: "inline-block",
                              width: "180px",
                              height: "130px",
                              position: "relative",
                              ml: 2,
                              mb: 2,
                              mx: 8,
                              // backgroundColor: item?.image?.m ? "inherit" : "#f0f0f0",
                              // border: selectedOption.includes(item?._id) ? "2px solid blue" : "none",
                            }}
                          >
                            <img
                              src={`${cloudfrontUrl}${item?.image?.m}`} // Adjusted for CloudFront URL
                              alt="img"
                              height="130px"
                              width="180px"
                            />
                          </Paper>
                        )}
                      </FormControl>
                    ) : (
                      <div
                        className={`block-option ${
                          selectedOption.find((id) => id === item?._id) && "selected"
                          // item.voters.includes("640ac0c25439b6144cdf6ab2") && "selected"
                          // 640ac0c25439b6144cdf6ab2
                        }`}
                        key={index}
                        onClick={() =>
                          disableBlock ? {} : handleOptionClick(item?._id, mcq?.is_multiple)
                        }
                      >
                        {item?.image?.m && (
                          <div
                            className="block-image"
                            style={{
                              backgroundImage: `url(${
                                item?.image?.m.startsWith(cloudfrontUrl)
                                  ? item?.image?.m
                                  : cloudfrontUrl + item?.image?.m
                              })`,
                              backgroundSize: "contain",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              height: `${item?.bound}px`,
                            }}
                          ></div>
                        )}
                        <div className="block-details">
                          <div className="block-text">{item.text}</div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            )}
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <ButtonMain
                onClick={handleMcqSubmit}
                disabled={disabled || disableBlock || !isLoggedIn}
                className={isLoggedIn && "userInputButton"}
                style={{ padding: `${loading && isLoggedIn ? "5px 25px" : ""}` }}
              >
                {loading ? <CircularProgress size={17} /> : checkUserInputs ? "Update" : "Save"}
              </ButtonMain>
              {isModerator !== -1 && block?.carousel_container && (
                <Image>
                  <Tooltip title="This Block Tagged to Carousel Block">
                    <LocalOfferRoundedIcon fontSize="large" color="primary" />
                  </Tooltip>
                </Image>
              )}
            </div>

            <ArtView art={(block || mcq)?.art} />
            {userDetails.role === userRoles.REVIEWER && (
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={open ? "long-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleBlockProperties}
                sx={{ float: "right" }}
              >
                <MoreVertIcon />
              </IconButton>
            )}
          </div>
          <ToastNotify ref={toastRef} duration={3000} />
          {userDetails.role === userRoles.REVIEWER && (
            <LearnerProperties
              anchorEl={anchorEl}
              open={open}
              handleClose={() => setAnchorEl(null)}
              obtainedMark={blockActivity?.credit}
              maxMark={block?.score || 0}
              blockType={block?.type}
              attempts={blockActivity?.NoOfAttempts}
              keywords={block?.keywords}
              correctAnswer={block.mcqs.filter((c) => c.is_correct === true)}
              scoreType={block?.typeOfBlock}
            />
          )}
        </>
      )}
    </>
  );
};

export default MCQView;
